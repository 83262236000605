<template>
    <div v-if="selectedDocumentsId.length > 0">
        <p>
            Documents To Link: 
            <span v-for="(document, index) in selectedDocumentsId" :key="index">
                <span v-if="index === selectedDocumentsId.length - 1">
                    <strong>
                        {{ document.title }}
                    </strong>.
                </span>
                <span v-else>
                    <strong>
                        {{ document.title }}
                    </strong>,
                </span>
            </span>
        </p>
    </div>

    <DataTable 
        :value="documentsFilteredByCountriesReduced" 
        :loading="loadingDocuments" 
        responsiveLayout="scroll" 
        showGridlines
        stripedRows

        v-model:filters="filters /*filters*/"  
        filterDisplay="menu"



        paginator 
        :rows="20" 
        :rowsPerPageOptions="[20, 50]" 
    >
        <template #header>
            <div style="display: flex; flex-direction: column">
                <div class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start">
                    <!-- <Button 
                    type="button" 
                    icon="pi pi-plus" 
                    label="Add Document" 
                    class="p-button-outlined"
                    @click="openAddDocument()" 
                    /> -->
                    <h5 style="margin-bottom: 0;">Documents</h5>
                </div>

                <!-- Clear Button, FilterApplication and keywordSearch  -->
                <div style="display: flex; justify-content: flex-end; align-items: center;">
                    <div style="display: flex; justify-content: center; align-items: center; gap: 1rem;">
                        <label >Show documents for all topics</label>
                        <InputSwitch 
                            v-model="showAllDocuments"
                            class="me-3"
                        />
                    </div>


                    <span class="p-input-icon-left me-3" style="position: relative;">
                        <i class="pi pi-search" style="position: absolute; left: 5%; top:50%"  />
                        <InputText 
                            v-model="filters['global'].value" 
                            placeholder="Keyword Search" 
                            class="keywordSearch"
                        />
                    </span>

                    <Dropdown
                        class="me-3"    
                        v-model="filterDocumentSourceType"
                        :options="documentSources"
                        optionLabel="name"
                        optionValue="documentSourceId"
                        :filter="true"
                        placeholder="Filter Document Type"
                        style="width: 10rem;"
                        :showClear="true"
                    >
                    </Dropdown>

                    <Dropdown 
                        class="me-3"
                        v-model="this.country" 
                        :options="this.countriesOptions" 
                        optionLabel="name"
                        optionValue="countryId" 
                        :filter="true" 
                        placeholder="Select Country"
                        showClear 
                        style="width: 10rem;"
                    />

                    <Dropdown 
                        class="me-3"
                        v-model="manufacturerId"
                        :options="companies"
                        optionLabel="name"
                        optionValue="companyId"
                        :filter="true"
                        placeholder="Select Manufacturer"
                        showClear 
                        style="width: 10rem;"
                    />
                    
                </div>
            </div>
        </template>
        <Column 
            field="title" 
            header="Title"  
            style="width: 30%"
        /> 
        <Column 
            :field="'dateAdded'" 
            :header="'Date Added'" 
            :key="'dateAdded'" 
            style="width: 15%"
            bodyStyle="text-align: center"
        >
            <template #body="slotProps">
                {{ formatDate(slotProps.data.dateAdded) }}
            </template>
        </Column>
        <Column 
            :field="'documentDate'" 
            :header="'Document Date'" 
            :key="'documentDate'" 
            style="width: 15%"
            bodyStyle="text-align: center"
        >
            <template #body="slotProps">
                {{ formatDate(slotProps.data.documentDate) }}
            </template>
        </Column>
        <Column :field="'sourceName'" :header="'Source Name'" :key="'sourceId'">
            <template #body="slotProps">
                {{ slotProps.data.sourceName }}
            </template>
        </Column>

        <Column :exportable="false" style="min-width: 5rem">
            <template #body="slotProps">
                <div style="display: flex; ">
                    <div>
                        <div v-if="slotProps.data.text">
                            <Button 
                                icon="pi pi-eye" 
                                class="p-button-rounded p-button-success mx-1"
                                @click="openPreviewDocumentDialog(slotProps.data)"
                                @mouseenter="hoverPreviewDocumentDialogIn(slotProps.data)"
                                @mouseleave="hoverPreviewDocumentDialogOut" 
                            />
                        </div>
                        <div v-else>
                            <Button 
                                icon="pi pi-eye" 
                                class="p-button-rounded p-button-success mx-1" 
                                :disabled="true" 
                            />
                        </div>
                    </div>

                    <div>
                        <div v-if="slotProps.data.contract">
                            <Button 
                                icon="pi pi-file" 
                                class="p-button-rounded p-button-success mx-1"
                                @click="openPreviewDocumentContracDialog(slotProps.data)"
                                @mouseenter="hoverPreviewDocumentContractDialogIn(slotProps.data)"
                                @mouseleave="hoverPreviewDocumentContractDialogOut"
                            >
                                <font-awesome-icon icon="fa-solid fa-file-contract" />
                            </Button>
                        </div>
                        <div v-else>
                            <Button icon="pi pi-file" class="p-button-rounded p-button-success mx-1" :disabled="true">
                                <font-awesome-icon icon="fa-solid fa-file-contract" />
                            </Button>
                        </div>
                    </div>

                    <div>
                        <div v-if="slotProps.data.filePublicUrl">
                            <Button 
                                icon="pi pi-file-pdf" 
                                class="p-button-rounded p-button-success mx-1"
                                @click="openPreviewDocumentFileDialig(slotProps.data)"
                            > 
                            </Button>
                        </div>
                        <div v-else>
                            <Button 
                                icon="pi pi-file-pdf" 
                                class="p-button-rounded p-button-success mx-1"
                                :disabled="true"
                            >
                            </Button>
                        </div>
                    </div>

                </div>
            </template>
        </Column>

        <!-- multiple Documents Checkbox -->
        <Column 
            bodyStyle="text-align: center"
        >
            <template #body="slotProps">
                <Checkbox 
                    v-model="selectedDocumentsId"
                    :value="
                        {
                            documentId:slotProps.data.documentId,
                            title:slotProps.data.title,
                        }
                    "
                />
            <!-- v-model="createSWOTItemForm.global" -->
            </template>
        </Column>
    </DataTable>


     <!-- Preview Document  -->
     <Dialog v-model:visible="previewDocumentDialog" :style="{ width: '80%' }" :modal="true">
        <template #header>
            <div v-if="selectedRowDocumentDialog.title">
                <h5> Preview <strong>{{ selectedRowDocumentDialog.title }} </strong> </h5>    
            </div>
        </template>
        <div v-if="selectedRowDocumentDialog">
            <div v-html="selectedRowDocumentDialog.text"></div>
        </div>
    </Dialog>

    <!-- Preview Document Hover -->
    <div class="modalPreview" v-show="previewHoverDocumentDialog">
        <div class="modalContainer">
            <div v-if="selectedRowDocument">
                <h5>Preview <strong>{{ selectedRowDocument.title }} </strong> </h5>
                <div v-html="selectedRowDocument.text" class="modalContainer-Text"></div>
            </div>
            <div v-else>
                loading
            </div>
        </div>
    </div>

    <!-- Preview DocumentContract  -->
    <Dialog v-model:visible="previewDocumentContractDialog" :style="{ width: '80%' }" :modal="true">
        <template #header>
            <h5> Contract for: {{ selectedRowDocumentContractDialog.title }}</h5>
        </template>
        <div>
            {{ selectedRowDocumentContractDialog.contract }}
        </div>
    </Dialog>

    <!-- preview DocumentContract Hover  -->
    <div class="modalPreview" v-show="previewHoverDocumentContractDialog">
        <div class="modalContainer">
            <div v-if="selectedRowDocumentContract">
                <h5>Contract <strong>{{ selectedRowDocumentContract.title }} </strong> </h5>
                <div>
                    {{ selectedRowDocumentContract.contract }}
                </div>
            </div>
            <div v-else>
                loading
            </div>
        </div>
    </div>

    <!-- Preview DocumentFile -->
    <Dialog v-model:visible="previewDocumentFileDialog" :style="{ width: '80%' }" :modal="true">
        <template #header>
            <div v-if="selectedRowDocumentFile.title">
                <h5> File for {{ selectedRowDocumentFile.title }}</h5>
            </div>
        </template>
        <div>
            <a :href="selectedRowDocumentFile.filePublicUrl" target="_blank" rel="Document File" class="document-File">
                <i class="pi pi-file-pdf" style="font-size: 2rem"></i>
                <label for="file">{{
                    selectedRowDocumentFile.filePublicUrl.split('/')[selectedRowDocumentFile.filePublicUrl.split('/').length
                    - 1] }}</label>
            </a>
        </div>
    </Dialog>
    


</template>
<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Dialog from 'primevue/dialog';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import formatToGlobalDate from '../../../../../../shared/helpers/date.helper';

export default {
    name: 'ProgramOverviewDocumentFilteredDataTable',
    components:{
        DataTable, 
        Column,
        Button,
        Checkbox,
        InputText,
        Dropdown,
        InputSwitch,
        Dialog,
    },
    emits: ['setDocumentsForLinkProgram','changeShowAllDocuments'],
    props: [
        'documentsFilteredByCountries',
        'companies',
        'countryId',
        'countriesOptions',
        'showAllDocumentsForLinkDocumentsProgram',
        'loadingDocumentsForLink',
        'documentSources'
        
    ],
    data(){
        return {
            loadingDocuments:true,
            documentsFilteredByCountriesReduced: null,
            selectedDocumentsId: [],

            // filters
            filters:null,

            // manufacturerId
            manufacturerId:null,

            // countryId
            country:null,
            // DocumentSource , 
            filterDocumentSourceType: null,
            // showAllDocuments,
            showAllDocuments: false,

            // PreviewDialog
            previewDocumentDialog : false,
            selectedRowDocumentDialog: null,
            previewHoverDocumentDialog:false,
            selectedRowDocument : null,

            previewDocumentContractDialog: false,
            selectedRowDocumentContractDialog: null,
            previewHoverDocumentContractDialog:false,
            selectedRowDocumentContract: null,
        
            previewDocumentFileDialog: false,
            selectedRowDocumentFile: null,
            
        }
    },
    created() {
        this.initFilters();        
    },
    async mounted(){
        // this.documentsFilteredByCountriesReduced=this.documentsFilteredByCountries.slice(0, 100);
        // this.documentsFilteredByCountriesReduced= this.documentsFilteredByCountries;
        
        this.country = this.countryId
        this.showAllDocuments = this.showAllDocumentsForLinkDocumentsProgram
    },
    computed:{

    },
    methods: {
        formatDate(date) {
            return formatToGlobalDate(date);
        },

        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: {
                operator: FilterOperator.AND,
                constraints: [
                    { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                ],
                },
            };
        },
        clearFilter() {
          this.initFilters();
        },

        filterDocumentsFilteredByCountriesReduced(){
            this.loadingDocuments = true;
            const deepClonedDocumentsFilteredByCountries = JSON.parse(JSON.stringify(this.documentsFilteredByCountries));

            let filteredDocuments = deepClonedDocumentsFilteredByCountries;

            if(this.manufacturerId){
                filteredDocuments = filteredDocuments.filter(document => 
                    document.documentCompanies.some(company => company.companyId === this.manufacturerId)
                );
            }

            if(this.country){
                filteredDocuments = filteredDocuments.filter(document => 
                    document.documentCountries.some(country => country.countryId === this.country) 
                );
            }
            if(this.filterDocumentSourceType){
                filteredDocuments = filteredDocuments.filter(document => 
                    // document.documentCountries.some(country => country.countryId === this.country) 
                    document.documentSourceTypeId === this.filterDocumentSourceType
                );
            }

            this.documentsFilteredByCountriesReduced = filteredDocuments
            this.loadingDocuments = false;
        },

        setSelectedDocumentsId(){
            this.$emit("setDocumentsForLinkProgram",{documentsList: this.selectedDocumentsId});
        },

        // PreviewDialog
        openPreviewDocumentDialog(document) {
            this.previewDocumentDialog = true;
            this.previewHoverDocumentDialog = false;
            this.selectedRowDocumentDialog = document;
        },
        hoverPreviewDocumentDialogIn(document) {
            this.previewHoverDocumentDialog = true;
            this.selectedRowDocument = document;
        },
        hoverPreviewDocumentDialogOut() {
            this.previewHoverDocumentDialog = false;
            this.selectedRowDocument = null;
        },

        // 

        openPreviewDocumentContracDialog(document) {
            this.previewDocumentContractDialog = true;
            this.selectedRowDocumentContractDialog = document
        },

        hoverPreviewDocumentContractDialogIn(document) {
            this.previewHoverDocumentContractDialog = true;
            this.selectedRowDocumentContract = document;
        },
        
        hoverPreviewDocumentContractDialogOut() {
            this.previewHoverDocumentContractDialog = null
            this.selectedRowDocumentContract = null;
        },


        openPreviewDocumentFileDialig(document) {
            this.previewDocumentFileDialog = true;
            this.selectedRowDocumentFile = document
        },


    },
    watch:{
        manufacturerId() {
            this.filterDocumentsFilteredByCountriesReduced()
        },
        country(){
            this.filterDocumentsFilteredByCountriesReduced()
        },
        filterDocumentSourceType(){
            this.filterDocumentsFilteredByCountriesReduced()
        },

        selectedDocumentsId(){
            this.setSelectedDocumentsId()
        },

        showAllDocuments(){
            if(this.showAllDocuments){
                this.$emit("changeShowAllDocuments",{ showAllDocBool: true});
            }
            else{
                this.$emit("changeShowAllDocuments",{ showAllDocBool: false});
            }

        },
        documentsFilteredByCountries(newVal){
            if (newVal) {
                this.filterDocumentsFilteredByCountriesReduced()
            }
        }
    },
}
</script>
<style scoped>
.keywordSearch{
   /* padding: 0 0 0 2.5rem  !important; */
   padding: 0.2rem 0 0.2rem 2.5rem  !important;
}

.filterDropdown{
   /* padding: 0 0 0 2.5rem  !important; */
   padding: 0 0.2rem 0 0  !important;
}

.modalPreview {
    position: fixed;
    z-index: 1101;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    pointer-events: none;
}

.modalContainer {
    height: 50%;
    width: 50%;
    overflow: hidden;
    background-color: white;
    margin: 0.75rem;
    transform: translate3d(0px, 0px, 0px);
    border-radius: 15px;
    padding: 1rem;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
    /* animation: 0.5s ease-in-out alternate swingViewport ; */
}
    
</style>    