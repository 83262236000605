<template>
    <div v-if="summary">
        <div class="container" style="position: relative; display: flex;">
            <!-- Button Prev -->
            <Button @click="prev" icon="pi pi-angle-left" style="
                    position: absolute;
                    left: -3%;
                    top: 10%;
                " />
            <!-- Program Summary -->
            <div style=" 
                    display: flex;  
                    justify-content: space-between; 
                    align-items: center; 
                    margin-left: 0%; 
                    margin-right: 0%;
                "
            >
                <!-- Market Values -->
                <div 
                    v-for="(item, index) in array[p - 1]" 
                    :key="index" 
                    style="  
                        width: 30%;  
                        height: 20%;  
                        display:flex; 
                        flex-direction: column;  
                        justify-content: space-between;
                        align-items: center;"
                    >
                    <div style="display:grid; place-items: center;">
                        <div>
                            <h5>{{ item.year }}</h5>
                        </div>
                        <!-- $ -->
                        <InputText 
                            :class=" (programStartYear <= item.year && item.year <= programEndYear)? 'summaryText-onYear' :'summaryText' "
                            type="text" 
                            min="0" 
                            style="
                                width: 80%;
                                text-align: right;
                            " 
                            @input="changeSummaryValue($event, index)"
                            v-model="array[p - 1][index].value"
                        />

                        <InputText 
                            :class=" (programStartYear <= item.year && item.year <= programEndYear)? 'summaryText-onYear' :'summaryText' " 
                            type="number" 
                            min="0" 
                            style="
                                width: 80%;
                                text-align: right;
                            " 
                            @input="changeSummaryUnit($event, index)" 
                            v-model:value="array[p - 1][index].unit" 
                        />
                    </div>
                </div>
                <!-- MARKETS VALUES SUM -->
                <!-- position: relative; -->
                <div style="
                        width: 30%;  
                        height: 30%; 
                        margin-right: 2px;
                        gap: 0.22rem;
                        display:flex; 
                        flex-direction: column;  
                        justify-content: space-between;
                        align-items: center;
                    "
                >
                    <!-- <div style="
                            width: 100%;
                            height: 7rem;
                            top: -10%;
                            z-index: 1;
                            position: absolute;
                            border: 1px solid black;
                        "
                    >
                    </div> -->

                    <div style="font-size: 1rem;">
                        Sum
                    </div>

                    <div 
                        style="
                            width: 80%;
                            border-bottom: solid 1px rgb(192, 192, 192);; 
                            border-radius: 0px; 
                            text-align: right; 
                            padding: 2.5% 7% 2.5% 5%;
                            font-size: 1rem;
                        "
                    >
                        {{getProgramMarketValuesSum()}}
                    </div>

                    <div style="
                            width: 80%;
                            border-bottom: solid 1px rgb(192, 192, 192);; 
                            border-radius: 0px; 
                            text-align: right; 
                            padding: 2.5% 7% 2.5% 5%;
                            font-size: 1rem;
                        "
                    >
                    {{getProgramMarketUnitSum()}}
                    </div>

                </div>
                
            </div>
            <!-- Button Next -->
            <Button 
                @click="next" 
                icon="pi pi-angle-right" 
                style="
                    position: absolute;
                    right: -3%;
                    top: 10%;
                " />
        </div>
    </div>
    <div v-else-if="scenario">
        <div class="container mb-2  ">
            <div class="row">
                <div class="col-4"></div>
                <div class="col-4" style="
                            display:grid; place-items: center;
                        ">
                </div>
            </div>
        </div>
        <div class="container container-Summary" style=" 
                background-color: white; 
                position: relative; 
                margin-bottom: 0.2rem; 
            ">
            <!-- Scenario percentages, if baseScenarioTrue not show Pecentages -->
            <div v-if="scenarioIndex != 0" 
                style=" 
                    display: flex;  
                    justify-content: space-between; 
                    align-items: center; 
                    margin-left: 0%;
                    margin-right: 0%;
                "
            >
                <div 
                    v-for="(item, index) in array[p - 1]" 
                    :key="index" 
                    style="  
                        width: 30%;  
                        height: 20%;  
                        "
                >
                    <div style="display:grid; place-items: center;">
                        <!-- % -->
                        <InputText 
                            type="number" 
                            min="0" 
                            :class=" (programStartYear <= item.year && item.year <= programEndYear)? 'summaryText-onYear' :'summaryText' "
                            style=" 
                                width: 80%; 
                                text-align: right; 
                            " @input="changeScenarioPercent($event, index)" v-model:value="array[p - 1][index].percent" />
                    </div>
                </div>
                <!-- MarketValues Sum space-->
                <div style="
                        width: 30%;  
                        height: 100%; 
                        margin-right: 2px;
                        gap: 0.22rem;
                        display:flex; 
                        flex-direction: column;  
                        justify-content: space-between;
                        align-items: center;
                    "
                >
                    <div> 
                    </div>
                </div>

            </div>
            <!-- Base Scenario ValuesOrTotal, units -->
            <div style=" 
                    display: flex;  
                    justify-content: space-between; 
                    align-items: center; 
                    margin-left: 0%; 
                    margin-right: 0%;
                ">
                <!-- Market Values -->
                <div v-for="(item, index) in array[p - 1]" :key="index"
                    style="  
                        width: 30%;  
                        height: 20%;  
                        display:flex;  
                        flex-direction: column;
                        justify-content: space-between; 
                        align-items: center;
                    "
                >
                    <div style="display:grid; place-items: center;">
                        <!-- value or total -->
                        <!-- $ -->
                        <InputText 
                            type="text" 
                            min="0" 
                            :class=" (programStartYear <= item.year && item.year <= programEndYear)? 'summaryText-onYear' :'summaryText' "
                            style="
                                width: 80%;
                                text-align: right;
                                margin-bottom: 2px;
                            " 
                            @input="changeScenarioValue($event, index)" 
                            v-model="array[p - 1][index].value" />
                        <!-- Units -->
                        <!-- # -->
                        <InputText 
                            type="number" 
                            min="0" 
                            :class=" (programStartYear <= item.year && item.year <= programEndYear)? 'summaryText-onYear' :'summaryText' "
                            style="
                                width: 80%;
                                text-align: right;
                            " @input="changeScenarioUnit($event, index)" v-model:value="array[p - 1][index].unit" />
                    </div>
                </div>
                <!-- MarketValues Sum   -->
                <div style="
                        width: 30%;  
                        height: 20%;  
                        display:flex;  
                        flex-direction: column;
                        justify-content: space-between; 
                        align-items: center;
                    "
                >
                    <div style="
                            width: 80%;
                            border-bottom: solid 1px rgb(192, 192, 192);; 
                            border-radius: 0px; 
                            text-align: right; 
                            padding: 2.5% 7% 2.5% 5%;
                            font-size: 1rem;
                        "
                    >
                        {{getProgramMarketValuesSum()}}
                    </div>
                    <div style="
                            width: 80%;
                            border-bottom: solid 1px rgb(192, 192, 192);; 
                            border-radius: 0px; 
                            text-align: right; 
                            padding: 2.5% 7% 2.5% 5%;
                            font-size: 1rem;
                        "
                    >
                        {{getProgramMarketUnitSum()}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="document">
        <div class="container my-2">
            <div style="
                    display: flex; 
                    justify-content: space-between; 
                    align-items: center; 
                    margin-left: 0%; 
                    margin-right: 0%;
                    position: relative;
                "
            >
                <!-- MARKET VALUES TITLE -->
                <div style="margin-right: 6%; margin-top: 2.5%; ">
                    <strong>Market Values</strong>
                </div>

                <!-- MARKETS VALUE ICONS -->
                <div
                    style="
                        width: 10%; 
                        position: absolute; 
                        padding-top: 0.2%; 
                        top: 30%; 
                        left:6%; 
                        display: flex; 
                        flex-direction: column; 
                        gap: 0.7rem;
                    "
                >
                    <label><strong>$</strong></label>
                    <label><strong>#</strong></label>
                </div>

                <!-- DOCUMENTS MARKETS VALUES -->
                <div v-for="(item, index) in sumMarket[p - 1]" :key="index" style="  
                        width: 30%;  
                        height: 20%; 
                        margin-right: 2px;
                    ">
                    <div style="text-align: center;">
                        {{ item.year }}
                    </div>
                    <div 
                        v-if="documentMarketValues.filter(docVal => docVal.year === item.year).length > 0" 
                        :class=" (programStartYear <= item.year && item.year <= programEndYear)? 'summaryText-onYear' :'summaryText' "
                        style="
                            width: 80%;
                            border-bottom: solid 1px rgb(192, 192, 192);; 
                            border-radius: 0px; 
                            text-align: right; 
                            padding: 2.5% 7% 2.5% 5%;"
                        
                        >
                        {{ documentMarketValues.filter(docVal => docVal.year === item.year)[0].marketValue }}
                    </div>
                    <div 
                        v-if="documentMarketValues.filter(docVal => docVal.year === item.year).length > 0" 
                        :class=" (programStartYear <= item.year && item.year <= programEndYear)? 'summaryText-onYear' :'summaryText' "
                        style="
                            width: 80%;
                            border-bottom: solid 1px rgb(192, 192, 192);; 
                            border-radius: 0px; 
                            text-align: right; 
                            padding: 2.5% 7% 2.5% 5%;"
                        
                        >
                        {{ documentMarketValues.filter(docVal => docVal.year === item.year)[0].unit }}
                    </div>
                    <div v-else style="
                            width: 80%;
                            border-bottom: solid 1px  rgb(192, 192, 192); 
                            border-radius: 0px; 
                            text-align: right; 
                            padding: 2.5% 7% 2.5% 5%;
                        "
                        :class=" (programStartYear <= item.year && item.year <= programEndYear)? 'summaryText-onYear' :'summaryText' "
                        >
                        0
                    </div>
                </div>

                <!-- MARKETS VALUES SUM -->
                <div style="
                        width: 30%;  
                        height: 20%; 
                        margin-right: 2px;
                    "
                >
                    <div
                        style="
                            text-align: center;
                        "
                    >
                        Sum
                    </div>
                    <div 
                        style="
                            width: 80%;
                            border-bottom: solid 1px rgb(192, 192, 192);; 
                            border-radius: 0px; 
                            text-align: right; 
                            padding: 2.5% 7% 2.5% 5%;"
                    >
                        {{getDocumentMarketValuesSum()}}
                    </div>
                    <div 
                        style="
                            width: 80%;
                            border-bottom: solid 1px rgb(192, 192, 192);; 
                            border-radius: 0px; 
                            text-align: right; 
                            padding: 2.5% 7% 2.5% 5%;"
                    >
                        {{getDocumentMarketUnitsSum()}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

export default {
    name: 'CarouselProgramA',
    components: {
        Button,
        InputText,
    },
    emits: ['sumP', 'subP', 'getSummaryMarketValue', 'getScenarioMarketValue','changeIsCopyToBaseScenario', 'addMaxValue', 'subMinValue'],
    props: [
        'programStartYear',
        'programEndYear',
        'summary',
        'scenario',
        'document',
        'minValue',
        'maxValue',
        'p',
        'summaryMarketValues',
        'scenarioMarketValues',
        'documentMarketValues',
        'sumMarket',
        'scenarioIndex',
        'baseScenario',
        'isCopyToBaseScenario',
        'baseScenarioMarketCopy'
    ],
    async mounted() {
        if (this.summary) {
            this.getSummary(this.summaryMarketValues)
            this.$emit('getSummaryMarketValue', this.array)
        }
        if (this.scenario) {
            this.getSummary(this.scenarioMarketValues)
            this.$emit("getScenarioMarketValue", this.array, this.scenarioIndex)
        }
        // if this.document only use The sum market, and documentMarketValues onlyRead 
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            array: [],
            arrayDiff: 9,
        }
    },
    methods: {
        // format coma to dot on values 
        formatValue(value){
            return parseFloat(value).toFixed(3)
        },

        getSummary(summaryAndScenario) {
            let mi = this.minValue
            let ma = this.maxValue

            let ara2 = []
            // sum 
            const sas = summaryAndScenario
            if (sas && sas.length > 0) {
                let sum = sas
                sum = sum.sort((a, b) => a.year - b.year) //sort by year 
                let count = 0
                for (let mIndex = mi; mIndex <= ma; mIndex += this.arrayDiff + 1) {
                    let ara = []
                    for (let i = 0; i <= this.arrayDiff; i++) {
                        if (sum[count] != undefined && sum[count].year === mi + i) {
                            if (mi + i === sum[count].year) {
                                ara.push(sum[count])
                                count += 1
                            }
                        }
                        else {
                            const marketValues = {
                                "value": null,
                                "unit": null,
                                "total": null,
                                "year": mi + i,
                            }
                            ara.push(marketValues)
                        }
                        if (i === this.arrayDiff) {
                            ara2.push(ara)
                            mi += this.arrayDiff + 1
                        }
                    }
                    this.array = ara2
                }
            }
            // if sumMarket || sumUnits are going to create total fix in the future
            else {
                for (let mIndex = mi; mIndex <= ma; mIndex += this.arrayDiff + 1) {
                    let ara = []
                    for (let i = 0; i <= this.arrayDiff; i++) {
                        const marketValues = {
                            "value": null,
                            "unit": null,
                            "total": null,
                            "year": mi + i,
                        }
                        ara.push(marketValues)
                        if (i === this.arrayDiff) {
                            ara2.push(ara)
                            mi += this.arrayDiff + 1
                        }
                    }
                    this.array = ara2
                }
            }
        },
        //  next, prev SummaryMarket
        next() {
            if (this.array.length === this.p) {
                const lastYear = this.array[this.p - 1][this.array[this.p - 1].length - 1].year + 1;
                const pArray = []
                for (let i = 0; i <= this.arrayDiff; i++) {
                    const marketValues = {
                        "value": null,
                        "unit": null,
                        "year": lastYear + i,
                    }
                    pArray.push(marketValues)
                    if (i === this.arrayDiff) {
                        // console.log('pArray',pArray[pArray.length - 1]);
                        this.$emit('addMaxValue', pArray[pArray.length - 1] );
                        this.array.push(pArray)
                    }
                }
                this.$emit('sumP')
                // this.p = this.p + 1
                this.$emit('getSummaryMarketValue', this.array)
            }
            else {
                this.$emit('sumP')
            }
        },
        prev() {
            if (1 === this.p) {
                const firstYear = this.array[this.p - 1][0].year
                const prevRangeFirstYear = firstYear - (this.arrayDiff + 1)
                const pArray = []
                for (let i = 0; i <= this.arrayDiff; i++) {
                    const marketValues = {
                        "value": null,
                        "unit": null,
                        "year": prevRangeFirstYear + i,
                    }
                    pArray.push(marketValues)
                    if (i === this.arrayDiff) {
                        this.array.splice(0, 0, pArray);
                        this.$emit('subMinValue', pArray[0] );
                    }
                }
                this.$emit('getSummaryMarketValue', this.array);
            }
            else {
                this.$emit('subP');
            }
        },
        //  next, prev scenariosMarket and diff scenario
        addNextScenario(lastYear) {
            const pArray = []
            for (let i = 0; i <= this.arrayDiff; i++) {
                const marketValues = {
                    "value": null,
                    "total": null,
                    "unit": null,
                    "year": lastYear + i,
                }
                pArray.push(marketValues)
                if (i === this.arrayDiff) {
                    this.array.push(pArray)
                }
            }
        },
        addPrevScenario() {
            const firstYear = this.array[this.p - 1][0].year
            const prevRangeFirstYear = firstYear - (this.arrayDiff + 1)
            const pArray = []
            for (let i = 0; i <= this.arrayDiff; i++) {
                const marketValues = {
                    "value": null,
                    "total": null,
                    "unit": null,
                    "year": prevRangeFirstYear + i,
                }
                pArray.push(marketValues)
                if (i === this.arrayDiff) {
                    this.array.splice(0, 0, pArray);
                }
            }
        },
        addArrayDiffScenario() {
            if (this.array.length < this.sumMarket.length) {
                if (this.sumMarket[0][0] && this.array[0][0]) {
                    const sumFirstYear = this.sumMarket[0][0].year
                    const arrayFistYear = this.array[0][0].year
                    if (sumFirstYear != arrayFistYear) {
                        this.addPrevScenario();
                    }
                    else {
                        const arraylength = this.array.length - 1
                        const arraylengthLength = this.array[arraylength].length - 1
                        const lastYear = this.array[arraylength][arraylengthLength].year + 1;

                        const sumlength = this.sumMarket.length - 1;
                        const sumlengthLength = this.sumMarket[sumlength].length - 1;
                        const sumLastYear = this.sumMarket[sumlength][sumlengthLength].year + 1;

                        if (lastYear < sumLastYear) {
                            this.addNextScenario(lastYear)
                        }
                    }
                }
                else {
                    null
                }
            }
        },
        // onChange summary , scenario value , total , Unit
        changeSummaryValue(event, index) {
            const numericValues =  event.target.value.replace(/[^\d.]|[.](?=.*[.])|(\.\d{4,})/g , '');
            this.array[this.p - 1][index].value = numericValues

            // this.array[this.p - 1][index].value = event.target.value

            if (this.array[this.p - 1][index].unit === null) {
                this.array[this.p - 1][index].unit = 0
            }
            this.$emit('getSummaryMarketValue', this.array)
        },
        changeSummaryUnit(event, index) {
            console.log('change units');
            this.array[this.p - 1][index].unit = event.target.value;
            if (this.array[this.p - 1][index].value === null) {
                console.log('change value to 0 ');
                this.array[this.p - 1][index].value = 0
            }
            this.$emit('getSummaryMarketValue', this.array)
        },
        changeScenarioPercent(event, index) {
            let float = event.target.value
            if (float) {
                this.array[this.p - 1][index].percent = parseFloat(float);
            }
            else {
                this.array[this.p - 1][index].percent = null;
            }


            let a = parseFloat(this.baseScenarioToCarouselFormat()[this.p - 1][index].value)
            let b = ((this.baseScenarioToCarouselFormat()[this.p - 1][index].value) * (this.array[this.p - 1][index].percent) / 100)
            let c = a + b ; //parseInt()

            // validate if c have more than 3 decimals 
            let cToText = c.toString();
            let cParts = cToText.split('.');

            const c3DecimateBool =  (cParts.length === 2 && cParts[1].length >= 3)? true : false 

            let a2 = parseFloat(this.baseScenarioToCarouselFormat()[this.p - 1][index].unit)
            // let b2 = ((this.baseScenarioToCarouselFormat()[this.p - 1][index].unit) * (this.array[this.p - 1][index].percent) / 100)
            // let c2 = a2 + b2; //parseInt()

            this.array[this.p - 1][index].value = (c3DecimateBool)?c.toFixed(3) : c 
            // this.array[this.p - 1][index].unit = parseInt(c2)
            this.array[this.p - 1][index].unit = a2
            this.$emit('getScenarioMarketValue', this.array, this.scenarioIndex)
        },
        changeScenarioValue(event, index) {
            let numericValues = event.target.value.replace(/[^\d.]|[.](?=.*[.])/g, '');
            // let float = event.target.value.replace(/[^\d.]|[.](?=.*[.])|(\.\d{4,})/g , '');
            // let float = event.target.value

            if (numericValues) {
                // console.log('si',numericValues);
                // this.array[this.p - 1][index].value = parseFloat(event.target.value)
                this.array[this.p - 1][index].value = numericValues
            }
            else {
                this.array[this.p - 1][index].value = null
            }
            this.array[this.p - 1][index].percent = null;
            this.$emit('getScenarioMarketValue', this.array, this.scenarioIndex)
        },
        changeScenarioUnit(event, index) {
            let float = event.target.value
            if (float) {
                this.array[this.p - 1][index].unit = parseFloat(event.target.value)
            }
            else {
                this.array[this.p - 1][index].unit = null
            }
            this.array[this.p - 1][index].percent = null;
            this.$emit('getScenarioMarketValue', this.array, this.scenarioIndex)
        },
        // baseScenario 
        baseScenarioToCarouselFormat() {
            const chunkSize = this.arrayDiff + 1;
            let baseScenario = this.baseScenario
            let baseScenarioCarousel = []
            for (let i = 0; i < baseScenario.length; i += chunkSize) {
                const chunk = baseScenario.slice(i, i + chunkSize);
                baseScenarioCarousel.push(chunk)
            }
            return baseScenarioCarousel
        },

        //GetSum or Total of marketValues
            //Documents 
        getDocumentMarketValuesSum(){
            // console.log(this.documentMarketValues);
            let documentMarketValuesSum = 0
            
            this.documentMarketValues.map(value => {
                documentMarketValuesSum += parseFloat(value.marketValue);
            })
            return  (documentMarketValuesSum % 1 !==0) ? documentMarketValuesSum.toFixed(3) : documentMarketValuesSum;
        },

        getDocumentMarketUnitsSum(){
            let documentMarketValuesSum = 0
            
            this.documentMarketValues.map(value => {
                documentMarketValuesSum += parseFloat(value.unit);
            })
            return (documentMarketValuesSum % 1 !==0) ? documentMarketValuesSum.toFixed(3) : documentMarketValuesSum;

        },
            // ProgramValues
        
        getProgramMarketValuesSum(){
            // console.log(this.array.flat());
            let sumMarketValuesSum = 0
            this.array.flat().map(value=> {
                if(value.value){
                    sumMarketValuesSum += parseFloat(value.value)
                }
            })
            return (sumMarketValuesSum % 1 !==0)?sumMarketValuesSum.toFixed(3):  sumMarketValuesSum
        },
        getProgramMarketUnitSum(){
            let sumMarketValuesSum = 0
            this.array.flat().map(value=> {
                if(value.unit){
                    sumMarketValuesSum += parseFloat(value.unit)
                }
            })
            return (sumMarketValuesSum % 1 !==0)?sumMarketValuesSum.toFixed(3):  sumMarketValuesSum
        },
    },
    watch: {
        // 'scenarioMarketValues'(newVal) {
        //     if (newVal) {
        //         // console.log('a');
        //         // this.getSummary(newVal);
        //         // this.$emit("getScenarioMarketValue", { array: this.array, scenarioIndex: this.scenarioIndex })
        //     }
        // },
        'sumMarket.length'(newVal) {
            if (newVal) {
                if (this.scenario) {
                    // console.log(newVal);
                    // console.log('array add');
                    this.addArrayDiffScenario();
                    this.$emit('getScenarioMarketValue', this.array, this.scenarioIndex);
                }
            }
        },

        // validate for copyProgramToBaseScenario
        'isCopyToBaseScenario'(newVal){
            if(newVal){
                if(newVal === true){
                    this.getSummary(this.baseScenarioMarketCopy)
                    this.$emit('getScenarioMarketValue', this.array, this.scenarioIndex)
                    this.$emit('changeIsCopyToBaseScenario');
                }
            }
        }
    }
}


</script>

<style scoped>
/* .p-inputtext {
    padding: 0.2rem 1.7rem !important;
} */

h5 {
    font-size: 0.9rem;
}

.documentsFields {
    padding: 0.2rem 1.7rem !important;
}

/* Chrome, Safari , Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    /* not native */
    /* margin: 0.3rem; */
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
    /* not native */
}

.summaryText {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    /* border-bottom: 0; */
    border-bottom: solid 1px rgb(192, 192, 192);
    border-radius: 0px;
}
.summaryText-onYear {
    background-color: yellow;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    /* border-bottom: 0; */
    border-bottom: solid 1px rgb(192, 192, 192);
    border-radius: 0px;
}

</style>

